import React, {
  useCallback,
  useEffect,
  useState,
} from "react";
import { Button, Paper } from "@material-ui/core";
import {
    useNotify,
} from "react-admin";

import { getOptions } from "../../utils";
import { API_URL } from "../../configs/constants";

import { useStyles } from "../../components/keys/styles";
import { myModal } from "./createContext";
import { KeyList } from "./keyList";
import { AddModal } from "./addModal";

const key_uri = API_URL + "/users/keys";

export const KeyManager = (props) => {
  const notify = useNotify();
  const classes = useStyles();
  const [data, setdata] = useState();
  const [open, setopen] = useState(false);
  const handleClose = useCallback(
    (e) => setopen(typeof e === "boolean" ? e : false),
    []
  );

  const userId =
    props?.id || window.location.href.split("users/")[1].split("/")[0];
  // console.log(userId);

  const addKeyFunc = async ({ name = "" }) => {
    try {
      if (!name) return notify("Debe agregar un nombre para cada llave...");
      let opt = getOptions("application/json");
      opt.method = "POST";
      opt.body = JSON.stringify({
        name,
        userId,
      });
      const keyData = await fetch(key_uri, opt).then((res) => res.json());
      // console.log(keyData);
        if (keyData.status >= 400 || !keyData?.id) {
          return notify(`LLave "${name || keyData?.name}" no ha podido ser creada!`);
        }

      if (keyData?.key) {
        await notify(`LLave "${name || keyData?.name}" creada con exito!`);
        return setdata(keyData);
        // else await fetchKeyFunc();
      } else {
        await notify(`LLave "${name || keyData?.name}" actualizada con exito!`);
        return await fetchKeyFunc();
      }
    } catch (err) {
      // console.log(err);
      return err;
    }
  };
  const fetchKeyFunc = async () => {
    try {
      let opt = getOptions("application/json");
      const keyData = await fetch(`${key_uri}/owner/${userId}`, opt).then(
        (res) => res.json()
      );
      // console.log(keyData);
      if (!keyData?.id || keyData?.status >= 400) {
        return setdata(undefined);
      }
      // console.log(data === keyData, data);
      return setdata(keyData);
    } catch (err) {
      // console.log(err);
      await notify("Sin API-KEYS para mostrar");
      return setdata(undefined);
    }
  };
  const deleteKeyFunc = async ({ id = "" }) => {
    try {
      if (!id) return await notify("Debe asignar un ID para eliminar la llave...");
      let opt = getOptions("application/json");
      opt.method = "DELETE";
      const keyData = await fetch(`${key_uri}/${id}`, opt).then((res) =>
        res.json()
      );
      // console.log(keyData);
      if (keyData?.isDeleted || keyData?.status <= 400) {
        await notify("Se ha eliminado la llave con exito!");
        setdata(undefined);
        return await fetchKeyFunc();
      } else return await notify("La llave no se ha podido eliminar...");
    } catch (err) {
      // console.log(err);
      return await notify("La llave no se ha podido eliminar...");
    }
  };

  const addKey = useCallback(addKeyFunc, [data]);
  // const fetchKey = useCallback(fetchKeyFunc, [data]);
  const deleteKey = useCallback(deleteKeyFunc, [data]);

  useEffect(() => {
    fetchKeyFunc();
    // eslint-disable-next-line
  }, []);

  return (
    <myModal.Provider value={{ open, setopen: handleClose }}>
      <Paper className={classes.manager} elevation={1}>
        <h4 style={{ textAlign: "left", width: "100%" }}>
          Accesos por Api-Key
        </h4>

        <KeyList data={data} deleteKey={deleteKey} userId={userId} />

        <Button
          onClick={() => handleClose(true)}
          variant={data ? "outlined" : "contained"}
          className={[classes.addButton, data ? "on_update" : ""].join(" ")}
          color="primary"
        >
          {data ? "ACTUALIZAR LLAVE" : "AGREGAR LLAVE"}
        </Button>
        <AddModal user={userId} addKey={addKey} past={data} />
      </Paper>
    </myModal.Provider>
  );
};
