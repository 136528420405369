import React from "react";
import {
  Datagrid,
  TextField,
  EmailField,
  FunctionField,
  CreateButton,
  EditButton,
  DeleteWithConfirmButton
} from "react-admin";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { RESOURCES } from "../../utils/constants";
import { Tags } from "../../components/Tags";
import { UserFilter } from "../../components/UserFilter";
import { UserPagination } from "../../components/UserPagination";
import { RACustomList } from "../../components/RACustomList";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as PencilIcon } from "../../assets/icons/pencil.svg";
import { ReactComponent as UserWhiteOutline } from "../../assets/icons/user_white_outline.svg";
import { ListToolbarActions } from "../../components/ListToolbarActions";

const useStyles = makeStyles({
  editButton: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "rgba(250, 250, 250, 0.8)"
    }
  },
  deleteWithConfirmButton: {
    backgroundColor: "transparent"
  },
  actionsCell: {
    display: "flex",
    alignItems: "center"
  }
});

export const UserList = props => {
  const classes = useStyles();

  return (
    <RACustomList
      {...props}
      exporter={false}
      title={"Usuarios"}
      actions={
        <ListToolbarActions
          buttons={[
            {
              raButton: CreateButton,
              props: {
                key: "create-user-btn",
                icon: <UserWhiteOutline />,
                label: "CREAR USUARIO"
              }
            }
          ]}
        />
      }
      filters={<UserFilter />}
      bulkActionButtons={false}
      pagination={<UserPagination />}
    >
      <Datagrid rowClick="show">
        <FunctionField
          label="Acceso a MiCopefrut"
          render={record => (
            <Box display="flex" justifyContent="center">
              <p>{!!record.username ? "Si" : "No"}</p>
            </Box>
          )}
        />
        <TextField source="username" label="Id de usuario" sortable={false} />
        <TextField source="names" label="Nombres" sortable={false} />
        <TextField source="surnames" label="Apellidos" sortable={false} />
        <EmailField
          source="email"
          label="Correo"
          className={"link-text"}
          sortable={false}
        />
        <TextField source="userType" label="Tipo de usuario" sortable={false} />
        <Tags source="permissions" label="Permisos" sortable={false} />
        <FunctionField
          label=""
          render={record => (
            <div className={classes.actionsCell}>
              <EditButton
                className={classes.editButton}
                icon={<PencilIcon />}
                label=""
                record={record}
                basePath={`/${RESOURCES.USERS}`}
              />
              <DeleteWithConfirmButton
                label=""
                icon={<TrashIcon />}
                className={classes.deleteWithConfirmButton}
                confirmTitle="Eliminar usuario"
                confirmContent="Esta acción es irreversible ¿Deseas continuar?"
                record={record}
                resource={RESOURCES.USERS}
              />
            </div>
          )}
        />
      </Datagrid>
    </RACustomList>
  );
};
