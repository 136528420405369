export const PERMISSIONS = {
  OPERATIONS: "operations",
  FINANCES: "finances",
  MANAGEMENT: 'manager',
  OTHERS: "others"
};

export const USER_TYPES = {
  INTERNAL: "INTERNO",
  EXTERNAL: "EXTERNO"
};

export const API_URL =
  process.env.REACT_APP_API_URL || "http://localhost:8000/v1";
export const AD_POST_LOGOUT_REDIRECT_URL =
  process.env.REACT_APP_AD_POST_LOGOUT_REDIRECT_URL || "http://localhost:3000";
export const AD_CLIENT_ID =
  process.env.REACT_APP_AD_CLIENT_ID || "c4ea492a-641f-4dc4-b57e-996b2ec00023";
export const AD_LOGIN_REDIRECT_URL =
  process.env.REACT_APP_AD_LOGIN_REDIRECT_URL || "http://localhost:3000/login";
export const AD_OIDC_ISSUER =
  process.env.REACT_APP_AD_OIDC_ISSUER ||
  "https://login.microsoftonline.com/29818d7d-1530-4fd6-9aef-41ebdbc541f7/v2.0/";

export const NEWS_IMAGE_MAX_SIZE =
  process.env.REACT_APP_NEWS_IMAGE_MAX_SIZE || 1024 * 1024 * 5; // 5MB default

export const USER_PAGINATION_OPTIONS =
  process.env.REACT_APP_USER_PAGINATION_OPTIONS || "25,50,75,100";
