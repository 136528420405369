import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { Grid, FormControl, Select, MenuItem } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { COLORS } from "../utils/constants";

const useStyles = makeStyles({
  selectPageContainer: {
    height: "32px",
    width: "auto",
    display: "flex",
    alignContent: "center",
    justifyContent: "center"
  },
  selectPageFormControl: {
    "& .MuiOutlinedInput-input": {
      paddingBottom: 0,
      paddingTop: 0
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: COLORS.APPLE
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.APPLE
      }
    },
    "& .MuiFormControl-root": {
      borderTopColor: "transparent",
      borderBottomColor: "transparent",
      borderLeftolor: "transparent",
      borderRightColor: "transparent"
    }
  },
  selectPage: {
    height: "100%",
    fontSize: "13px",
    "& :hover": {
      backgroundColor: "transparent"
    },
    "& :focus": {
      backgroundColor: "transparent"
    }
  },
  selectInput: {
    "& ul.MuiMenu-list": {
      borderRadius: "4px"
    },
    "& ul.MuiList-padding": {
      paddingTop: 0,
      paddingBottom: 0
    },
    "& li": {
      fontSize: "13px",
      height: "32px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        backgroundColor: "#E9E9F0"
      }
    }
  }
});

export const SelectDropdown = ({ values, currentValue, onChange }) => {
  const classes = useStyles();

  const renderMenuItems = () =>
    values.map(value => (
      <MenuItem key={value.text} value={value.value}>
        {value.text}
      </MenuItem>
    ));

  return (
    <Grid className={classes.selectPageContainer}>
      <FormControl variant="outlined" className={classes.selectPageFormControl}>
        <Select
          className={classes.selectPage}
          labelId="cope-select-dropdown"
          id="cope-select-dropdown"
          value={currentValue}
          onChange={onChange}
          IconComponent={ExpandMore}
          MenuProps={{ className: classes.selectInput }}
        >
          {renderMenuItems()}
        </Select>
      </FormControl>
    </Grid>
  );
};

SelectDropdown.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired
    })
  ),
  currentValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};
