import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import Login from '../components/LoginPage';
import LoginForm from "../components/LoginForm";
import loginFooter from "../assets/images/login-footer.png";

const styles = () =>
    createStyles({
        card: {
            maxWidth: "400px",
            minWidth: "300px",
            marginTop: "6em"
        }
    });

const LoginPage = props => (
    <Login {...props} backgroundImage={loginFooter}>
        <LoginForm />
    </Login>
);

export default withStyles(styles)(LoginPage);
