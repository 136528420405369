import { API_URL } from "../configs/constants";
import { RESOURCES } from "../utils/constants";
import { getOptions } from "../utils";

// cached resources
const users = [];
let userSearch = null;

/**
 *
 *
 * @param {string} url
 * @param {*} options
 * @param {("getList"|"getOne"|"create"|"update"|"delete")} action
 * @returns
 */
const fetchData = (url, options, action = null) => {
  return fetch(url, options)
    .then(res => res.json())
    .then(json => {
      if (json.status < 200 || json.status >= 300) {
        return Promise.reject(json);
      } else {
        if (action && action === "getList" && url.includes(RESOURCES.USERS)) {
          users.length = 0;
          users.push(...json.data);
        }
        return Promise.resolve(json);
      }
    });
};

/**
 * Intercept requests to perform some actions and determine if a real
 * request will be made to the API or some cached value will be used
 *
 * @param {string} url
 * @param {Object} options
 * @param {{
 *     pagination: { page: number, perPage: number },
 *     sort: { field: string, order: string },
 *     filter: { search: (string|null) }
 *   }} params
 * @returns
 */
async function userInterceptor(url, options, params) {
  const {
    pagination: { page, perPage },
    filter: { search }
  } = params;
  if (!users.length || search !== userSearch) {
    userSearch = search;
    await fetchData(url, options, "getList");
  }
  const startIndex = page === 1 ? 0 : (page - 1) * perPage;
  const endIndex = startIndex + perPage;
  const usersResult = users.slice(startIndex, endIndex);
  return { data: usersResult, total: users.length };
}

/**
 * Clear cached values for the user resources
 *
 */
function clearUserResources() {
  users.length = 0;
  userSearch = null;
}

export default {
  getList(resource, params) {
    const options = getOptions();
    const baseUrl = `${API_URL}/${resource}`;
    const url =
      params.filter && params.filter.search
        ? `${baseUrl}?search=${params.filter.search}`
        : baseUrl;
    if (resource === RESOURCES.USERS) {
      return userInterceptor(url, options, params);
    }
    return fetchData(url, options, "getList");
  },
  getOne(resource, params) {
    const options = getOptions();
    if (resource === RESOURCES.USERS) {
      clearUserResources();
    }
    return fetchData(`${API_URL}/${resource}/${params.id}`, options);
  },
  create(resource, params) {
    const options = getOptions();
    options.method = "POST";
    options.body = JSON.stringify(params.data);
    if (resource === RESOURCES.USERS) {
      clearUserResources();
    }
    return fetchData(`${API_URL}/${resource}`, options);
  },
  update(resource, params) {
    const options = getOptions();
    options.method = "PATCH";
    options.body = JSON.stringify(params.data);
    if (resource === RESOURCES.USERS) {
      clearUserResources();
    }
    return fetchData(`${API_URL}/${resource}/${params.id}`, options);
  },
  delete(resource, params) {
    const options = getOptions();
    options.method = "DELETE";
    if (resource === RESOURCES.USERS) {
      clearUserResources();
    }
    return fetchData(`${API_URL}/${resource}/${params.id}`, options);
  }
};
