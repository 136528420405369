import React from "react";
import PropTypes from "prop-types";
import {
  SaveButton,
  useRedirect,
  useNotify,
  useLoading,
  useUpdateLoading
} from "react-admin";
import { getOptions } from "../utils";
import { API_URL } from "../configs/constants";

// React will complain about that it doesnt recognize some props
// this is known issue with React Admin (https://github.com/marmelab/react-admin/issues/3245)
// wich has not been resolved yet.
export const CreateNewsButton = ({
  resource,
  form,
  valid,
  label,
  className
}) => {
  const loading = useLoading();
  const { startLoading, stopLoading } = useUpdateLoading();
  const redirect = useRedirect();
  const notify = useNotify();

  const handleOnSubmitError = () => {
    stopLoading();
    notify("Ha ocurrido un error al intentar crear la noticia");
  };

  const handleClick = async () => {
    startLoading();
    try {
      const formState = form.getState();
      const fieldValues = formState.values;
      const formData = new FormData();

      formData.append(
        "image",
        fieldValues.news_image.rawFile,
        fieldValues.news_image.title
      );
      formData.append("article_url", fieldValues.article_url);
      formData.append("published_until", fieldValues.published_until);
      formData.append("news_order", fieldValues.news_order);

      const url = `${API_URL}/${resource}`;
      const options = getOptions("multipart/form-data");
      options.method = "POST";
      options.body = formData;

      const response = await fetch(url, options);

      if (response.status < 200 || response.status >= 300) {
        handleOnSubmitError();
      } else {
        stopLoading();
        notify("Noticia creada exitosamente!");
        redirect("/news");
      }
    } catch (error) {
      handleOnSubmitError();
    }
  };

  return (
    <SaveButton
      className={className}
      label={label}
      handleSubmitWithRedirect={handleClick}
      disabled={!valid}
      saving={loading}
    />
  );
};

CreateNewsButton.propTypes = {
  resource: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  valid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};
