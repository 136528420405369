import React from "react";
import {
  TextField,
  Datagrid,
  DateField,
  ImageField,
  UrlField,
  DeleteWithConfirmButton,
  CreateButton
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ReactComponent as NewsWhiteOutlineIcon } from "../../assets/icons/news_white_outline.svg";
import { RACustomList } from "../../components/RACustomList";
import { ListToolbarActions } from "../../components/ListToolbarActions";

const useStyles = makeStyles({
  deleteWithConfirmButton: {
    backgroundColor: "transparent"
  },
  rowCell: {
    "&:first-child": {
      // image cell
      minWidth: "100px",
      "& div": {
        display: "flex",
        justifyContent: "center"
      }
    },
    "&:nth-child(2)": {
      // url cell
      maxWidth: "600px",
      "& a": {
        wordBreak: "break-all"
      }
    }
  }
});

export const NewsList = props => {
  const classes = useStyles();

  return (
    <RACustomList
      {...props}
      exporter={false}
      title={"Noticias"}
      bulkActionButtons={false}
      actions={
        <ListToolbarActions
          buttons={[
            {
              raButton: CreateButton,
              props: {
                key: "create-news-btn",
                icon: <NewsWhiteOutlineIcon />,
                label: "NUEVA NOTICIA"
              }
            }
          ]}
        />
      }
    >
      <Datagrid classes={{ rowCell: classes.rowCell }}>
        <ImageField
          source="imageUrl"
          title="Imagen"
          label="Imagen"
          sortable={false}
        />
        <UrlField
          source="articleUrl"
          title="Noticia"
          label="Enlace Noticia"
          sortable={false}
        />
        <TextField
          source="order"
          title="Orden"
          label="Prioridad/Orden"
          textAlign="center"
          sortable={false}
        />
        <DateField
          source="publishedUntil"
          label="Expiración"
          sortable={false}
        />
        <DeleteWithConfirmButton
          label=""
          icon={<TrashIcon />}
          className={classes.deleteWithConfirmButton}
          confirmTitle="Eliminar noticia"
          confirmContent="Esta acción es irreversible ¿Deseas continuar?"
        />
      </Datagrid>
    </RACustomList>
  );
};
