import React, {
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Paper, IconButton, TextField as MuiTextField  } from "@material-ui/core";
import {
  useLoading,
  useUpdateLoading,
} from "react-admin";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useStyles } from "./styles";
import { Empty } from "./emptyKeys";

export const KeyList = memo(
  ({ data = undefined, deleteKey = () => {}, userId = "" }) => {
    const classes = useStyles();
    const [list, setlist] = useState(data);

    const loading = useLoading();
    const { startLoading, stopLoading } = useUpdateLoading();

    useEffect(() => {
      setlist(data);
    }, [data]);

    // console.log(data);

    const Key = ({ data = {} }) => {
      const { name, key, id, modified } = data;

      const handleDelete = useCallback(async () => {
        startLoading();
        await deleteKey({ id });
        stopLoading();
      }, [id]);

      return (
        <Paper className={classes.key} elevation={0}>
          <MuiTextField
            disabled
            label="Nombre"
            value={name || "Key Example Name"}
          />
          <MuiTextField disabled label="ID" value={id} />
          <MuiTextField
            disabled
            label="LLave"
            value={
              !key || key === "hidden" ? "*****-*******-****-**-*******" : key
            }
          />
          <MuiTextField
            disabled
            label="Modificación reciente"
            value={new Date(modified).toLocaleString()}
          />
          {/* <MuiTextField label="Dueño" value={owner} disabled /> */}
          <IconButton
            className={classes.deleteBtn}
            color="secondary"
            disabled={loading}
            onClick={handleDelete}
          >
            <DeleteIcon />
          </IconButton>
        </Paper>
      );
    };

    return (
      <div className={classes.holder}>
        {Array.isArray(list) ? (
          list.map((x, i) => <Key key={x?.id} data={x} />)
        ) : typeof list === "object" || list?.id ? (
          <Key data={list} />
        ) : (
          <Empty />
        )}
      </div>
    );
  }
);
