// Custom Menu items for React Admin
import React, { createElement, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources } from "react-admin";
import { withRouter } from "react-router-dom";

import { RESOURCES } from "../utils/constants";
import { USER_PAGINATION_OPTIONS } from "../configs/constants";
import { ReactComponent as NewsIcon } from "../assets/icons/news.svg";
import { ReactComponent as UserIcon } from "../assets/icons/user.svg";

const useStyles = makeStyles({
  menuItemLink: {
    marginBottom: "5%",
    "& .MuiListItemIcon-root": {
      marginRight: "5%"
    }
  }
});

const RACustomMenu = ({ onMenuClick, logout }) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);

  const renderIcon = useCallback(resource => {
    switch (resource.name) {
      case RESOURCES.USERS:
        return UserIcon;
      case RESOURCES.NEWS:
        return NewsIcon;
      default:
        return resource.icon;
    }
  }, []);

  return (
    <div>
      {resources.map(resource => (
        <MenuItemLink
          className={classes.menuItemLink}
          key={resource.name}
          to={
            resource.name === RESOURCES.USERS
              ? `/${resource.name}?filter=%7B%7D&order=ASC&page=1&perPage=${USER_PAGINATION_OPTIONS[0]}&sort=id`
              : `/${resource.name}`
          }
          primaryText={
            (resource.options && resource.options.label) || resource.name
          }
          leftIcon={createElement(renderIcon(resource))}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {isXSmall && logout}
    </div>
  );
};

export default withRouter(RACustomMenu);
