import React from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { COLORS } from "../utils/constants";

const Tags = ({ source, record = {} }) => (
  <div>
    {record[source] instanceof Array &&
      record[source].map((tag, i) => (
        <Chip
          key={`${i}${tag}`}
          label={tag}
          style={{
            backgroundColor: COLORS.ORANGE,
            color: "white",
            marginRight: "2px",
            marginTop: "2px"
          }}
        ></Chip>
      ))}
  </div>
);

Tags.defaultProps = {
  label: "Permisos",
  addLabel: true
};

Tags.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  source: PropTypes.string.isRequired,
  record: PropTypes.object
};

export { Tags };
