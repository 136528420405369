import { required, email, regex, choices } from "react-admin";
import { USER_TYPES } from "../configs/constants";

const validateSpaces = value =>
    typeof value === "string" && /^\s+$/.test(value)
        ? "Debe ser un texto válido"
        : undefined;

export const validateRut = [
    required("Requerido"),
    value => (value.length <= 10 ? undefined : "Máximo 10 caracteres"),
    regex(/^\d{7,9}(\d|k|K)$/, "Debe ser un rut sin puntos ni guion")
];

export const validateEmail = [
    required("Email requerido"),
    email("Formato de email inválido")
];

export const validateNames = [required("Nombres requeridos"), validateSpaces];
export const validateSurnames = [validateSpaces];
export const validateUserType = [
    required("Debe indicar el tipo de usuario"),
    choices(
        [USER_TYPES.EXTERNAL, USER_TYPES.INTERNAL],
        "Tipo de usuario inválido"
    )
];

export const validatePermissions = [
    required("Debe indicar al menos un permiso")
];

export const validateUser = (record = {}) => {
    const errors = {};

    if (record.userType === USER_TYPES.EXTERNAL) {
        if (!/^\d{7,9}(\d|k|K)$/.test(record.username)) {
            errors.username =  "El id de usuario debe ser un rut para usuarios externos";
        }
        
        if(!record.rutProducers || (Array.isArray(record.rutProducers) && !record.rutProducers.length)) {
            errors.rutProducers = ["Debe especificar al menos un rut de productor para un usuario externo"];
        }
    }

    return errors;
};

export const validateUserId = [required("requerido"), validateSpaces];
