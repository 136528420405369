import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";

const styles = {
  root: {
    padding: "2px 4px",
    display: "flex",
    flexFlow: "column nowrap",
    width: 450,
    height: 80
  },
  inputRoot: {
    display: "flex",
    flexDirection: "space-between",
    alignItems: "baseline"
  },
  input: {
    flex: 2
  },
  buttonContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center"
  },
  errorText: {
    color: "#f44336",
    fontSize: "0.75rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    width: "60%"
  },
  button: {
    alignSelf: "flex-end"
  }
};

class InputRut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      error: false,
      loading: false,
      helperText: ""
    };
    this.inputRef = React.createRef();
  }

  onKeyDown = e => {
    if (e.keyCode === 13) {
      this.validateAndInsert();
    }
  };

  validateAndInsert = async () => {
    const { enableValidation, asyncValidation } = this.props.options;
    const { validate, onAdd } = this.props;
    const value = this.state.value;

    if (enableValidation) {
      let result;
      if (asyncValidation) {
        this.setState({ loading: true });
        result = await validate(value);
        this.setState({ loading: false });
      } else {
        result = validate(value);
      }
      if (result.valid) {
        this.setState({ error: false, helperText: "" });
        onAdd(value);
        this.setState({ value: "" });
      } else {
        this.setState({ error: true, helperText: result.error });
      }
    } else {
      this.props.onAdd(this.state.value);
      this.setState({ value: "" });
    }

    try {
      this.inputRef.current.focus();
    } catch (e) {
      // TODO: add logger
    }
  };

  onClick = async () => {
    await this.validateAndInsert();
  };

  onChange = e => {
    const { value } = e.target;
    this.setState({ value }, () => {
      if (!value) {
        this.setState({ error: false, helperText: "" });
      }
    });
  };

  render() {
    const { label, classes, error, helperText } = this.props;
    return (
      <Box className={classes.root}>
        <Box className={classes.inputRoot}>
          <TextField
            className={classes.input}
            inputRef={this.inputRef}
            label={`${label} *`}
            value={this.state.value}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            error={this.state.error || error}
            InputProps={{
              endAdornment: this.state.loading && (
                <InputAdornment position="end">
                  <CircularProgress size={24} />
                </InputAdornment>
              )
            }}
          />
          <Box className={classes.buttonContainer}>
            <Button
              className={classes.button}
              onClick={this.onClick}
              variant="contained"
              color="primary"
            >
              Agregar
            </Button>
          </Box>
        </Box>
        {(this.state.error || error) && (
          <div className={classes.errorText}>
            {error ? helperText[0] : this.state.helperText}
          </div>
        )}
      </Box>
    );
  }
}

InputRut.propTypes = {
  onAdd: PropTypes.func.isRequired,
  label: PropTypes.string,
  validation: PropTypes.func,
  options: PropTypes.shape({
    enableValidation: PropTypes.bool,
    asyncValidation: PropTypes.bool
  }),
  error: PropTypes.bool,
  helperText: PropTypes.any
};

InputRut.defaultProps = {
  label: "Rut Productor",
  options: {
    enableValidation: false,
    asyncValidation: false
  }
};

export { InputRut };
export default withStyles(styles)(InputRut);
