import React, { useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as TrashIcon} from "../assets/icons/trash.svg";

const styles = {
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#EFEFEF",
        width: 205,
        height: 36,
        paddingLeft: 15,
        marginTop: 6
    },
    text: {
        width: "9em",
        color: "#464646",
        fontSize: 14,
    },
    button: {
        size: 10
    },
    svg: {
      height: 22,
      width: 20
    }
};

let RutRow = ({ value, onDelete, classes, interactive = true }) => {
    const onClick = useCallback(() => onDelete(value), [value, onDelete]);
    return (
        <li className={classes.root}>
            <div className={classes.text}>{value}</div>
            {interactive && (
                <IconButton className={classes.button} onClick={onClick}>
                    <SvgIcon component={TrashIcon} className={classes.svg}/>
                </IconButton>
            )}
        </li>
    );
};

RutRow = React.memo(RutRow);

export { RutRow };
export default withStyles(styles)(RutRow);
