import React from "react";
import { createTheme } from "@material-ui/core/styles";
import { Admin, Resource } from "react-admin";
import { Route } from "react-router-dom";
import { UserList, UserCreate, UserShow, UserEdit } from "./screens/users";
import { NewsList, NewsCreate } from "./screens/news";
import UserIcon from "@material-ui/icons/Group";
import NewsIcon from "@material-ui/icons/PermMedia";
import authProvider from "./providers/auth";
import { i18nProvider } from "./providers/i18n";
import LoginPage from "./screens/Login";
import Logout from "./components/Logout";
import customDataProvider from "./providers/data";
import { COLORS, RESOURCES } from "./utils/constants";
import SSOLogout from "./components/SSOLogout";
import RACustomLayout from "./components/RACustomLayout";

const myTheme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "Roboto", "Arial"].join(",")
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: COLORS.CHERRY
      }
    },
    MuiTablePagination: {
      root: {
        display: "none"
      }
    },
    MuiCheckbox: {
      colorPrimary: {
        color: COLORS.APPLE,
        "&$checked": {
          color: COLORS.APPLE
        }
      }
    },
    MuiRadio: {
      colorPrimary: {
        color: COLORS.APPLE,
        "&$checked": {
          color: COLORS.APPLE
        }
      }
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: COLORS.APPLE,
        "&:hover": {
          backgroundColor: COLORS.APPLE_HOVER
        }
      },
      textPrimary: {
        backgroundColor: COLORS.APPLE,
        color: "white",
        "&:hover": {
          backgroundColor: COLORS.APPLE_HOVER
        }
      },
      root: {
        "&.Mui-disabled": {
          backgroundColor: COLORS.LIGHT,
          color: COLORS.MID
        }
      }
    },
    MuiInputBase: {
      root: {
        "& .MuiFilledInput-input": {
          background: "#fff"
        }
      }
    },
    MuiFilledInput: {
      underline: {
        borderBottomColor: COLORS.ORANGE,
        "&:hover:not($disabled):after": {
          borderBottomColor: COLORS.ORANGE //its when its hover and input is focused
        },
        "&:hover:not($disabled):before": {
          borderBottomColor: COLORS.ORANGE //its when you hover and input is not foucused
        },
        "&:after": {
          borderBottomColor: COLORS.ORANGE //when input is focused, Its just for example. Its better to set this one using primary color
        }
      },
      root: {
        "& *": {
          fontWeight: 600
        }
      }
    },
    MuiTableHead: {
      root: {
        color: "#464646"
      }
    },
    MuiTableCell: {
      root: {
        "& span": {
          fontSize: "12px",
          fontWeight: "400"
        }
      },
      body: {
        "& span": {
          fontSize: "12px",
          fontWeight: "500"
        }
      }
    },
    MuiChip: {
      root: {
        borderRadius: 0
      }
    },
    MuiFormControl: {
      root: {
        "& > label:not(.Mui-focused)": {
          fontWeight: 600
        }
      }
    }
  },
  sidebar: {
    width: 'auto', // The default value is 240
    minWidth: 100,
  }
});

const customRoutes = [
  <Route exac path="/sso_logout" component={SSOLogout} noLayout />
];

const App = () => (
  <Admin
    title="Portal de Usuarios MiCopefrut"
    theme={myTheme}
    appLayout={RACustomLayout}
    dataProvider={customDataProvider}
    authProvider={authProvider}
    loginPage={LoginPage}
    logoutButton={Logout}
    customRoutes={customRoutes}
    i18nProvider={i18nProvider}
  >
    <Resource
      name={RESOURCES.USERS}
      icon={UserIcon}
      show={UserShow}
      list={UserList}
      create={UserCreate}
      edit={UserEdit}
      options={{ label: "Usuarios" }}
    />

    <Resource
      name={RESOURCES.NEWS}
      icon={NewsIcon}
      list={NewsList}
      create={NewsCreate}
      options={{ label: "Noticias" }}
    />
  </Admin>
);

export default App;
