/**
 * Create an options object to use when using the fetch API
 *
 * @export
 * @param {string} contentType
 * @returns
 */
export function getOptions(contentType) {
  const headers = {
    Accept: "application/json"
  };
  if (contentType !== "multipart/form-data") {
    headers["Content-Type"] = "application/json";
  }
  const options = {
    mode: "cors",
    headers: new Headers({
      ...headers
    })
  };

  const token = localStorage.getItem("token");
  const { access_token = "" } = token ? JSON.parse(token) : {};
  options.headers.set("Authorization", `Bearer ${access_token}`);
  return options;
}
