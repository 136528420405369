import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    padding: "0px",
    borderRadius: "6px",
    boxShadow: "0px 1px 4px #00000033",
    fontFamily: "Montserrat",
    "& .MuiButton-label svg": {
      width: "15px",
      height: "15px",
      paddingLeft: "25px"
    },
    "& .MuiButton-label span": {
      padding: "15px 30px 15px 5px"
    }
  }
});

const ToolbarButton = ({ RAButton, buttonProps, children }) => {
  const classes = useStyles();

  return (
    <RAButton className={classes.button} {...buttonProps}>
      {children}
    </RAButton>
  );
};

ToolbarButton.propTypes = {
  RAButton: PropTypes.elementType.isRequired,
  buttonProps: PropTypes.object,
  children: PropTypes.element || PropTypes.object || PropTypes.array
};

export { ToolbarButton };
