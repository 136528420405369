import React from "react";
import {
  ImageInput,
  DateTimeInput,
  ImageField,
  TextInput,
  NumberInput,
  minValue,
  regex,
  required,
  FormWithRedirect,
  Toolbar,
  useNotify
} from "react-admin";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import { COLORS, URL_REGEX } from "../../utils/constants";
import { NEWS_IMAGE_MAX_SIZE } from "../../configs/constants";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
import { CreateNewsButton } from "../../components/CreateNewsButton";
import { currentDateValidation } from "../../utils/formValidators";

const useStyles = makeStyles({
  form: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 6px #0000001D",
    opacity: 1
  },
  createNewsTitle: {
    textAlign: "left",
    fontWeight: "lighter",
    letterSpacing: 0,
    color: COLORS.DARK,
    opacity: 1
  },
  fieldsContainer: {
    marginLeft: "55px",
    marginRight: "55px",
    display: "flex",
    flexDirection: "column"
  },
  inputContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: "300px",
    width: "35%",
    marginBottom: "30px",
    "& .MuiFormControl-root": {
      width: "100%"
    }
  },
  imageInputContainer: {
    "& [data-testid=dropzone]": {
      minWidth: "300px",
      width: "35%",
      padding: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "155px",
      backgroundColor: COLORS.LIGHT,
      borderRadius: "6px"
    },
    "& .MuiFormLabel-root": {
      fontFamily: "Montserrat",
      fontWeight: "lighter",
      fontSize: "17px",
      letterSpacing: 0,
      color: COLORS.DARK,
      opacity: 1,
      marginTop: "40px",
      marginBottom: "20px",
      "& + div": {
        padding: 0
      }
    }
  },
  uploadButtonPlaceholderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& :first-child": {
      marginRight: "25px"
    },
    "& p": {
      textAlign: "left",
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: 500,
      letterSpacing: 0,
      color: COLORS.DARK,
      opacity: 1
    }
  },
  toolbar: {
    paddingLeft: 0,
    backgroundColor: "transparent"
  },
  toolbarButtonsContainer: {
    width: "35%",
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "45px"
  },
  saveButton: {
    width: "100%",
    "& svg": {
      display: "none"
    }
  }
});

async function customFileGetter(event) {
  const files = [];
  const fileList = event.dataTransfer
    ? event.dataTransfer.files
    : event.target.files;

  for (var i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    files.push(file);
  }

  return files;
}

const maxImageFileSize = Number.parseFloat(
  NEWS_IMAGE_MAX_SIZE / 1000000
).toPrecision(3);

export const NewsCreate = props => {
  const classes = useStyles();
  const notify = useNotify();

  const onFileRejected = files => {
    const size = files[0].size || null;

    if (!size) {
      notify("Ha ocurrido un error al cargar el archivo");
    } else {
      const fileSize = Number.parseFloat(size / 1000000).toPrecision(3);

      if (fileSize > maxImageFileSize) {
        notify(
          `No se ha podido cargar el archivo. Revisa que no supere los ${maxImageFileSize} MB`
        );
      }
    }
  };

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form className={classes.form}>
          <Box className={classes.fieldsContainer}>
            <Box
              component="h3"
              id="create-news-title"
              className={classes.createNewsTitle}
            >
              Crear Noticia
            </Box>
            <Box display="flex" flexDirection="column">
              <Box className={classes.inputContainer}>
                <TextInput
                  source="article_url"
                  label="Enlace articulo"
                  validate={[
                    required(),
                    regex(URL_REGEX, "Debe ser una URL valida")
                  ]}
                />
              </Box>
              <Box className={classes.inputContainer}>
                <DateTimeInput
                  source="published_until"
                  label="Expiracion"
                  validate={[required(), currentDateValidation]}
                />
              </Box>
              <Box className={classes.inputContainer}>
                <NumberInput
                  source="news_order"
                  label="Prioridad"
                  validate={[
                    required(),
                    minValue(
                      1,
                      "La prioridad de la noticia comienza desde el número 1"
                    )
                  ]}
                />
              </Box>
              <Box className={classes.imageInputContainer}>
                <ImageInput
                  label="Imagen Noticia"
                  accept="image/*"
                  source="news_image"
                  maxSize={NEWS_IMAGE_MAX_SIZE}
                  validate={[required()]}
                  options={{
                    getFilesFromEvent: customFileGetter,
                    onDropRejected: onFileRejected
                  }}
                  placeholder={
                    <Box className={classes.uploadButtonPlaceholderContainer}>
                      <SvgIcon component={UploadIcon} viewBox="0 0 29 36" />
                      <p>
                        Arrastre la imagen aqui o seleccionela de su computador
                      </p>
                    </Box>
                  }
                >
                  <ImageField source="src" title="title" />
                </ImageInput>
              </Box>
            </Box>
            <Toolbar className={classes.toolbar}>
              <Box className={classes.toolbarButtonsContainer}>
                <CreateNewsButton
                  label="Guardar Noticia"
                  className={classes.saveButton}
                  valid={formProps.valid}
                  form={formProps.form}
                  resource={formProps.resource}
                />
              </Box>
            </Toolbar>
          </Box>
        </form>
      )}
    />
  );
};
