import React from "react";
import { Toolbar } from "@material-ui/core";
import {
  ShowController,
  ShowView,
  SimpleShowLayout,
  TextField,
  DeleteWithConfirmButton,
  EditButton,
} from "react-admin";
import { ReactComponent as PencilIcon } from "../../assets/icons/pencil.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { Tags } from "../../components/Tags";
import MultiRutField from "../../components/MultiRutField";
import { USER_TYPES } from "../../configs/constants";
import { ToolbarButton } from "../../components/ToolbarButton";

import { useStyles } from '../../components/keys/styles'
import { KeyManager } from "../../components/keys/keyManager";


const ShowActions = ({
  permissions,
  basePath,
  data,
  resource,
  id,
  classes,
}) => (
  <Toolbar className={classes.toolbar}>
    <ToolbarButton
      RAButton={DeleteWithConfirmButton}
      buttonProps={{
        key: "delete-user-btn",
        icon: <TrashIcon />,
        label: "ELIMINAR USUARIO",
        basePath,
        record: { id },
        resource,
        color: "default",
        confirmTitle: "Eliminar usuario",
        confirmContent: "Esta acción es irreversible ¿Deseas continuar?",
        id: "show-screen-delete-user-btn",
      }}
    />
    <ToolbarButton
      RAButton={EditButton}
      buttonProps={{
        key: "edit-user-btn",
        icon: <PencilIcon />,
        label: "EDITAR USUARIO",
        basePath,
        record: data,
        resource,
      }}
    />
  </Toolbar>
);

export const UserShow = props => {
  const classes = useStyles();
  return (
    <>
      <ShowController {...props}>
        {(controllerProps) => (
          <>
            <ShowView
              className={classes.showView}
              {...props}
              {...controllerProps}
              actions={<ShowActions classes={classes} id={props.id} />}
            >
              <SimpleShowLayout>
                <TextField source="username" label="Id de usuario" />
                <TextField source="names" label="Nombres" />
                <TextField source="surnames" label="Apellidos" />
                <TextField source="email" label="Email" />
                <TextField source="userType" label="Tipo de usuario" />
                {controllerProps.record &&
                  controllerProps.record.userType === USER_TYPES.EXTERNAL &&
                  controllerProps.record.rutProducers.length > 0 && (
                    <MultiRutField
                      source="rutProducers"
                      label="Ruts de Productores"
                      sortable={false}
                    />
                  )}
                <Tags source="permissions" label="Permisos" sortable={false} />
              </SimpleShowLayout>
            </ShowView>
            <KeyManager {...props} />
          </>
        )}
      </ShowController>
    </>
  );
};
