import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { userLogin as userLoginAction } from "react-admin";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import fruits from "../assets/images/fruits-group.png";
import logo from "../assets/images/logo-nombre.png";

const styles = ({ spacing }) =>
  createStyles({
    logo: {
      width: "200px"
    },
    icon: {
      marginRight: spacing()
    },
    card: {
      maxWidth: "400px",
      paddingRight: "4rem",
      paddingLeft: "4rem",
      paddingBottom: "4rem"
    },
    welcomeText: {
      fontSize: "30px",
      textAlign: "center",
      fontWeight: 300
    },
    fruits: {
      width: "80px"
    },
    contentText: {
      fontSize: "16px",
      textAlign: "center",
      paddingBottom: "30px",
      fontWeight: 300
    }
  });

const LoginForm = ({ classes, userLogin }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const { searchParams } = new URL(window.location.href);
    const code = searchParams.get("code");
    const state = searchParams.get("state");

    // If code is present, we came back from the provider
    if (code && state) {
      setLoading(true);
      userLogin({ code, state });
    }
  }, [userLogin]);

  const handleLogin = () => {
    setLoading(true);
    userLogin(); // Do not provide code, just trigger the redirection
  };

  return (
    <CardActions className={classes.card}>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <img alt="logo" src={logo} className={classes.logo} />
        </Grid>
        <Grid item xs={12}>
          <p className={classes.welcomeText}>Bienvenido</p>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <img alt="logo" src={fruits} className={classes.fruits} />
        </Grid>
        <Grid item xs={12}>
          <p className={classes.contentText}>
            Ingresa en el portal para recibir información clave de tu proceso
            productivo desde cualquier lugar.
          </p>
        </Grid>
        <Grid item xs={12} justifyContent="center" container={true}>
          <Button
            type="submit"
            color="primary"
            onClick={handleLogin}
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                className={classes.icon}
                size={18}
                thickness={2}
              />
            )}
            INICIAR SESIÓN CON MICROSOFT
          </Button>
        </Grid>
      </Grid>
    </CardActions>
  );
};

const mapDispatchToProps = {
  userLogin: userLoginAction
};

export default connect(
  undefined,
  mapDispatchToProps
)(withStyles(styles)(LoginForm));
