import React, { useEffect, useState } from "react";
import { Button, Modal, Paper } from "@material-ui/core";
import {
  useNotify,
  useLoading,
  useUpdateLoading,
} from "react-admin";
import { TextField as MuiTextField } from "@material-ui/core";
import { useStyles } from "./styles";
import { myModal } from "./createContext";

export const AddModal = ({ addKey = () => {}, past = undefined }) => {
  const notify = useNotify();
  const classes = useStyles();
  const [name, setname] = useState(past?.name || "");
  const loading = useLoading();
  const { startLoading, stopLoading } = useUpdateLoading();

  useEffect(() => {
    console.log(past);
    setname(past?.name || "");
  }, [past]);

  return (
    <myModal.Consumer>
      {({ open, setopen }) => {
        const handleAdd = async (e) => {
          e.preventDefault();
          if (!name) return notify("Debe agregar un nombre para cada llave...");
          startLoading();
          await addKey({ name }).finally((e) => {
            stopLoading();
            // setname("");
            setopen(false);
          });
        };
        const handleClose = () => {
          setname(past?.name ? past?.name : "");
          setopen(false);
        };
        return (
          <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            aria-labelledby="api-key-add-modal"
            aria-describedby="api-key-add-modal"
          >
            <Paper className={classes.addModal}>
              <nav>
                <h5>
                  {past?.name ? "Actualizar" : "Crear / Asignar"} una API-Key{" "}
                  {past?.name ? "de" : "a"} este usuario
                </h5>
              </nav>
              <form onSubmit={handleAdd}>
                <MuiTextField
                  className={classes.borderColorer}
                  disabled={loading}
                  label="Nombre de la llave"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />

                <i>
                  <b>*</b> Recuerda que debes mantener esta llave oculta y
                  resguardada en un sitio seguro. Solo se mostrara en esta
                  ocasion, por lo tanto, debes copiarla y almacenarla en un
                  sitio seguro...<b>*</b>
                </i>

                <div className={classes.actions}>
                  <Button
                    disabled={loading}
                    onClick={handleClose}
                    variant="text"
                    color="secondary"
                    className={classes.cancelButton}
                  >
                    CANCELAR
                  </Button>
                  <Button
                    disabled={loading}
                    onClick={handleAdd}
                    variant="contained"
                    color="primary"
                    className={classes.addButton}
                  >
                    AGREGAR
                  </Button>
                </div>
              </form>
            </Paper>
          </Modal>
        );
      }}
    </myModal.Consumer>
  );
};
