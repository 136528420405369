import React from 'react';
import { WarningOutlined } from "@material-ui/icons";
import { grey } from "@material-ui/core/colors";
import { useStyles } from "./styles";

export const Empty = () => {
  const classes = useStyles();

  return (
    <section className={classes.empty}>
      <WarningOutlined
        style={{
          fontSize: "96px",
          height: "96px",
          width: "96px",
          color: grey[400],
        }}
      />
      <p>Aun no hay nada por aqui...</p>
    </section>
  );
};
