import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { COLORS } from "../../utils/constants";

export const useStyles = makeStyles({
  toolbar: {
    justifyContent: "flex-end",
    paddingLeft: 0,
    paddingRight: 0,
    "& button": {
      marginLeft: "10px",
    },
    "& a": {
      marginLeft: "10px",
    },
    "& svg path": {
      color: "white",
      fill: "#fff",
      stroke: "#fff",
    },
    "& #show-screen-delete-user-btn": {
      backgroundColor: "#797979",
      color: "white",
    },
  },
  showView: {
    boxShadow: "0px 2px 6px #0000001D",
    background: "white",
    padding: "20px",
    "& .MuiToolbar-root + div": {
      padding: 0,
      width: "100%",
      "& .MuiPaper-root": {
        backgroundColor: "transparent",
        boxShadow: "none",
        display: "flex",
        width: "100%",
        gap: "16px",
        flexFlow: "column wrap",
        "& .MuiCardContent-root": {
          display: "flex",
          width: "100%",
          flexFlow: "column nowrap",
          gap: "16px",
          "& div.keys": {
            display: "inline-flex",
            width: "50%",
            flexFlow: "column nowrap",
            gap: "6px",
          },
        },
      },
      // target .MuiFormControl-root
      "& .ra-field:nth-child(-n+4)": {
        marginBottom: "30px",
        height: "50px",
        maxWidth: "300px",
        borderBottom: `1px solid ${COLORS.ORANGE}`,
        "& .MuiTypography-root": {
          fontWeight: 600,
        },
      },
      "& .MuiFormControl-root": {
        marginBottom: "30px",
        "& .MuiTypography-root": {
          fontWeight: 600,
        },
      },
      "& .MuiFormControl-root label + div": {
        fontWeight: "500",
      },
    },
  },
  empty: {
    display: "flex",
    position: "relative",
    width: "auto",
    height: "auto",
    minHeight: "224px",
    minWidth: "224px",
    margin: "auto",
    padding: "24px",
    flexFlow: "column wrap",
    justifyContent: "center",
    alignItems: "center",
  },
  addButton: {
    display: "flex",
    // marginLeft: "auto",
    position: "relative",
    "&.on_update": {
      color: COLORS.ORANGE,
      borderColor: COLORS.ORANGE,
      "&:hover": {
        borderColor: COLORS.ORANGE,
      },
    },
  },
  manager: {
    display: "flex",
    position: "relative",
    width: "calc(100% - 48px)",
    height: "100%",
    margin: "16px auto",
    padding: "24px",
    flexFlow: "column wrap",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    display: "flex",
    position: "relative",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  addModal: {
    padding: "24px",
    maxWidth: "640px",
    width: "calc(100% - 32px)",
    display: "flex",
    position: "relative",
    flexFlow: "column nowrap",
    gap: "16px",
    margin: "auto",
    height: "auto",
    maxHeight: "320px",
    minHeight: "160px",
    "& nav": {
      height: "28px",
      alignItems: "center",
      justifyItems: "flex-start",
      display: "flex",
      flexFlow: "row wrap",
      "& h5": {
        margin: "auto",
        marginLeft: "0px",
      },
    },
    "& form": {
      display: "flex",
      flexFlow: "column nowrap",
      gap: "16px",
      "& i": {
        fontSize: "12px",
        fontWeight: 300,
      },
    },
  },
  actions: {
    display: "flex",
    flexFlow: "row wrap",
    gap: "24px",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "24px",
  },
  holder: {
    display: "flex",
    width: "100%",
    padding: "0px",
    margin: "16px auto",
  },
  deleteIcon: {
    "& path": {
      color: `${grey[800]} !important`,
      fill: `${grey[800]} !important`,
      stroke: `${grey[800]} !important`,
    },
  },
  key: {
    display: "flex",
    flexFlow: "column nowrap",
    position: "relative",
    width: "100%",
    height: "auto",
    minHeight: "160px",
    gap: "24px",
    padding: "24px",
    background: grey[100],
  },
  deleteBtn: {
    position: "absolute",
    top: "8px",
    right: "8px",
    zIndex: 3,
  },
  borderColorer: {
    "& label": {
      color: `${grey[500]} !important`,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: `${COLORS.ORANGE} !important`,
    },
    "&:hover, &:active": {
      "& .MuiInput-underline:after": {
        borderBottomColor: `${COLORS.ORANGE} !important`,
      },
      "& label": {
        color: `${COLORS.ORANGE} !important`,
      },
    },
  },
});
