import {
  AD_POST_LOGOUT_REDIRECT_URL,
  AD_CLIENT_ID
} from "../configs/constants";

const SSOLogout = () => {
  window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?client_id=${AD_CLIENT_ID}&post_logout_redirect_uri=${encodeURI(
    AD_POST_LOGOUT_REDIRECT_URL
  )}`;
  return null;
};

export default SSOLogout;
