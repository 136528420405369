import React, { useState } from "react";
import axios from "axios";
import InputRut from "./InputRut";
import RutRow from "./RutRow";
import { useInput } from "react-admin";
import { API_URL } from "../configs/constants";

const MultipleRutInput = props => {
    const {
        input,
        meta: { touched, error }
    } = useInput(props);  
    const initialState = props.record[props.source] || [];
    const [producers, setProducers] = useState(initialState); //record[source]);

    const deleteProducer = rut => {
        const newProducers = producers.filter(value => {
            return value !== rut;
        });

        setProducers(newProducers);
        input.onChange({ target: { value: newProducers } });
    };

    const addProducer = rut => {
        const newProducers = producers.concat([rut]);
        setProducers(newProducers);
        input.onChange({ target: { value: newProducers } });
    };

    const validate = async rut => {
        let error = null;
        let valid = false;
        try {
            if (rut.length < 10) {
                error =
                    "Debe tener un largo de 10 carácteres. Rellene con 0 al principio si el rut tiene un largo inferior";
            } else if (!/^[01][0-9kK]{9}$/.test(rut)) {
                error = "Rut inválido";
            } else if (producers.includes(rut)) {
                error = "Rut duplicado. Este rut ya fue agregado anteriormente";
            } else {
                const token = localStorage.getItem("token");
                const { access_token = "" } = token ? JSON.parse(token) : {};
                const response = await axios.get(
                    `${API_URL}/producers?search=${rut}`,
                    {
                        headers: {
                            authorization: `Bearer ${access_token}`
                        }
                    }
                );

                valid = Boolean(response.data.data.length);
                error = valid ? null : "No existe un productor con este rut o se encuentra deshabilitado";
            }

            return { valid, error };
        } catch (e) {
            return {
                valid: false,
                error: "No hemos podido verificar el rut"
            };
        }
    };

    return (
        <div>
            <InputRut
                label={props.label}
                onAdd={addProducer}
                validate={validate}
                options={{ enableValidation: true, asyncValidation: true }}
                error={!!(touched && error)}
                helperText={touched && error}
            />
            <ul style={{ paddingLeft: 0 }}>
                {producers &&
                    producers.map((value, index) => {
                        return (
                            <RutRow
                                key={`RutRow-${index}`}
                                value={value}
                                onDelete={deleteProducer}
                            />
                        );
                    })}
            </ul>
        </div>
    );
};

export default MultipleRutInput;
