import React, { useRef, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Card, Avatar, createTheme, makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import LockIcon from "@material-ui/icons/Lock";
import { useHistory } from "react-router-dom";
import { useCheckAuth } from "ra-core";
import { defaultTheme, LoginForm as DefaultLoginForm } from "react-admin";

const useStyles = makeStyles(
    theme => ({
        main: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            height: "1px",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
        },
        card: {
            minWidth: 300,
            marginTop: "6em"
        },
        avatar: {
            margin: "1em",
            display: "flex",
            justifyContent: "center"
        },
        icon: {
            backgroundColor: theme.palette.secondary[500]
        }
    }),
    { name: "RaLogin" }
);

const Login = ({
    theme,
    classes: classesOverride,
    className,
    children,
    staticContext,
    backgroundImage,
    ...rest
}) => {
    const containerRef = useRef();
    const classes = useStyles({ classes: classesOverride });
    const muiTheme = useMemo(() => createTheme(theme), [theme]);
    let backgroundImageLoaded = false;
    const checkAuth = useCheckAuth();
    const history = useHistory();
    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push("/");
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history]);

    const updateBackgroundImage = () => {
        if (!backgroundImageLoaded && containerRef.current) {
            containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
            backgroundImageLoaded = true;
        }
    };

    // Load background image asynchronously to speed up time to interactive
    const lazyLoadBackgroundImage = () => {
        if (backgroundImage) {
            const img = new Image();
            img.onload = updateBackgroundImage;
            img.src = backgroundImage;
        }
    };

    useEffect(() => {
        if (!backgroundImageLoaded) {
            lazyLoadBackgroundImage();
        }
    });

    return (
        <ThemeProvider theme={muiTheme}>
            <div
                className={classnames(classes.main, className)}
                {...rest}
                ref={containerRef}
            >
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                        <Avatar className={classes.icon}>
                            <LockIcon />
                        </Avatar>
                    </div>
                    {children}
                </Card>
            </div>
        </ThemeProvider>
    );
};

Login.propTypes = {
    backgroundImage: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.object,
    staticContext: PropTypes.object
};

Login.defaultProps = {
    backgroundImage: "https://source.unsplash.com/random/1600x900/daily",
    theme: defaultTheme,
    children: <DefaultLoginForm />
};

export default Login;
