import React from "react";
import PropTypes from "prop-types";
import { TopToolbar, sanitizeListRestProps } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { ToolbarButton } from "./ToolbarButton";

const useStyles = makeStyles({
  toolbar: {
    paddingTop: 0,
    paddingBottom: 0
  }
});

const ListToolbarActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  buttons,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <TopToolbar
      className={`${className} ${classes.toolbar}`}
      {...sanitizeListRestProps(rest)}
    >
      {buttons.length > 0
        ? buttons.map(button => (
            <ToolbarButton
              key={`toolbar-${button.props.key}`}
              RAButton={button.raButton}
              buttonProps={{ basePath, ...button.props }}
            />
          ))
        : rest.children}
    </TopToolbar>
  );
};

ListToolbarActions.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      raButton: PropTypes.elementType.isRequired,
      props: PropTypes.object.isRequired
    }).isRequired
  )
};

export { ListToolbarActions };
