import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { Responsive, useLogout } from "react-admin";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ExitIcon from "@material-ui/icons/PowerSettingsNew";

const MyLogoutButton = forwardRef((props, ref) => {
  const logout = useLogout();
  const handleClick = () => {
    return logout({}, '/sso_logout');
  }
  return (<Responsive
    xsmall={
      <MenuItem onClick={handleClick} ref={ref}>
        <ExitIcon /> Cerrar Sesión
      </MenuItem>
    }
    medium={
      <Button onClick={handleClick} size="small" ref={ref}>
        <ExitIcon /> Cerrar Sesión
      </Button>
    }
  />);
});
export default connect()(MyLogoutButton);
