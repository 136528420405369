import React, { useEffect, useMemo, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Button, Grid, Toolbar } from "@material-ui/core";

import { USER_PAGINATION_OPTIONS } from "../configs/constants";
import { COLORS } from "../utils/constants";
import { SelectDropdown } from "./SelectDropdown";

const useStyles = makeStyles({
  toolbarContainer: {
    padding: "25px 17px 0",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  paginationContainer: {
    marginRight: "20px",
    height: "32px",
    width: "auto",
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-end",
    "& :first-child": {
      marginLeft: 0
    },
    "& :last-child": {
      marginRight: 0
    }
  },
  buttonContainer: {
    margin: "0 8px",
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    border: "1px solid #EBEBEB",
    borderRadius: "4px"
  },
  button: {
    width: "100%",
    height: "100%",
    padding: 0,
    minWidth: "auto",
    color: COLORS.DARK,
    borderRadius: "1px"
  },
  activeButton: {
    color: "#FFFFFF",
    backgroundColor: COLORS.APPLE
  }
});

const pagesQtySelectOptions = !USER_PAGINATION_OPTIONS
  ? [25, 50, 75, 100]
  : USER_PAGINATION_OPTIONS.split(",").map(option => parseInt(option, 10));

export const UserPagination = ({
  page,
  perPage,
  total,
  setPage,
  setPerPage
}) => {
  const classes = useStyles();

  const pagesQty = useMemo(() => Math.ceil(total / perPage) || 1, [
    total,
    perPage
  ]);
  const prevPage = page - 1;
  const nextPage = page + 1;
  const showPrevPageButton = page > 1;
  const showNextPageButton = page !== pagesQty;

  useEffect(() => {
    if (!pagesQtySelectOptions.includes(perPage)) {
      setPerPage(pagesQtySelectOptions[0]);
    }
  });

  const renderFirstPageButton = useCallback(() => {
    const { buttonContainer, button, activeButton } = classes;
    return (
      <Grid key="firtPage" item className={buttonContainer}>
        <Button
          className={`${button} ${page === 1 ? activeButton : ""}`}
          onClick={() => setPage(1)}
        >
          1
        </Button>
      </Grid>
    );
  }, [classes, page, setPage]);

  const renderLastPageButton = useCallback(() => {
    const { buttonContainer, button, activeButton } = classes;
    return (
      <Grid key="lastPage" item className={buttonContainer}>
        <Button
          className={`${button} ${page === pagesQty ? activeButton : ""}`}
          onClick={() => setPage(pagesQty)}
        >
          {pagesQty}
        </Button>
      </Grid>
    );
  }, [classes, page, pagesQty, setPage]);

  const renderThreeDots = ({ key, shouldRender }) => {
    return shouldRender ? (
      <Grid key={key} item className={classes.buttonContainer}>
        <Button className={classes.button} disabled>
          ...
        </Button>
      </Grid>
    ) : null;
  };

  const renderMiddlePaginationButtons = () => {
    const buttons = [];
    const startIndex = page === 1 ? page + 1 : page - 1;
    const endIndex = page === pagesQty ? page - 1 : page + 1;
    for (let i = startIndex; i <= endIndex; i++) {
      if (i === 1 || i === pagesQty) {
        continue;
      }
      const active = page === i;
      buttons.push(
        <Grid key={`page${i}`} item className={classes.buttonContainer}>
          <Button
            className={`${classes.button} ${
              active ? classes.activeButton : ""
            }`}
            onClick={() => setPage(i)}
          >
            {i}
          </Button>
        </Grid>
      );
    }
    return buttons;
  };

  const renderPageButtons = () => {
    const firstPageButton = renderFirstPageButton();
    const firstThreeDots = renderThreeDots({
      key: "firstThreeDots",
      shouldRender: prevPage - 1 > 1
    });
    const middlePaginationButtons =
      pagesQty > 1 ? renderMiddlePaginationButtons() : [];
    const lastThreeDots = renderThreeDots({
      key: "lastThreeDots",
      shouldRender: pagesQty - 1 > nextPage
    });
    const lastPageButton = pagesQty > 1 ? renderLastPageButton() : null;
    return [
      firstPageButton,
      firstThreeDots,
      ...middlePaginationButtons,
      lastThreeDots,
      lastPageButton
    ];
  };

  const onChangePerPage = e => {
    e.preventDefault();
    setPerPage(e.target.value);
  };

  return (
    <Toolbar className={classes.toolbarContainer}>
      <Grid container className={classes.paginationContainer}>
        {showPrevPageButton && (
          <Grid item className={classes.buttonContainer}>
            <Button
              className={classes.button}
              key="prevPage"
              onClick={() => setPage(prevPage)}
            >
              <ChevronLeft />
            </Button>
          </Grid>
        )}
        {renderPageButtons()}
        {showNextPageButton && (
          <Grid item className={classes.buttonContainer}>
            <Button
              className={classes.button}
              key="nextPage"
              onClick={() => setPage(nextPage)}
            >
              <ChevronRight />
            </Button>
          </Grid>
        )}
      </Grid>
      <SelectDropdown
        values={pagesQtySelectOptions.map(value => ({
          value,
          text: `${value} por página`
        }))}
        currentValue={
          pagesQtySelectOptions.includes(perPage)
            ? perPage
            : pagesQtySelectOptions[0]
        }
        onChange={onChangePerPage}
      />
    </Toolbar>
  );
};
