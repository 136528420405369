import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";
import { API_URL, AD_CLIENT_ID, AD_LOGIN_REDIRECT_URL, AD_OIDC_ISSUER } from "../configs/constants";
import { UserManager } from "oidc-client";

export const userManager = new UserManager({
    authority: AD_OIDC_ISSUER,
    client_id: AD_CLIENT_ID,
    redirect_uri: AD_LOGIN_REDIRECT_URL,
    response_type: "code",
    post_logout_redirect_uri: AD_LOGIN_REDIRECT_URL,
    scope: "https://graph.windows.net/Directory.AccessAsUser.All openid offline_access"
});

const cleanup = () => {
    // Remove the ?code&state from the URL
    window.history.replaceState(
        {},
        window.document.title,
        window.location.origin
    );
};

const getProfileFromToken = tokenJson => {
    const token = JSON.parse(tokenJson);
    const jwt = JSON.parse(window.atob(token.access_token.split(".")[1]));
    return { ...jwt };
};

const authProvider = async (type, params = {}, ...rest) => {
    if (type === AUTH_LOGIN) {
        // 1. Redirect to the issuer to ask authentication
        if (!params.code || !params.state) {
            userManager.signinRedirect();
            return; // Do not return anything, the login is still loading
        }

        // 2. We came back from the issuer with ?code infos in query params

        // oidc-client uses localStorage to keep a temporary state
        // between the two redirections. But since we need to send it to the API
        // we have to retrieve it manually
        const stateKey = `oidc.${params.state}`;
        const { code_verifier } = JSON.parse(
            localStorage.getItem(stateKey) || "{}"
        );

        // Transform the code to a token via the API
        const response = await fetch(`${API_URL}/oauth2/token`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ code: params.code, code_verifier, grant_type: 'authorization_code' })
        });

        if (!response.ok) {
            cleanup();
            return Promise.reject();
        }

        const token = await response.json();

        localStorage.setItem("token", JSON.stringify(token));
        userManager.clearStaleState();
        cleanup();
        return Promise.resolve('AUTH_LOGIN');
    } else if (type === AUTH_LOGOUT) {
        localStorage.removeItem("token");
        return Promise.resolve();
    } else if (type === AUTH_ERROR) {
        return params.status !== 401 ? Promise.reject() : Promise.resolve();
    } else if (type === AUTH_CHECK) {
        const token = localStorage.getItem("token");

        if (!token) {
            return Promise.reject();
        }

        // This is specific to the Google authentication implementation
        const jwt = getProfileFromToken(token);
        const now = new Date();

        if (now.getTime() > jwt.exp * 1000) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        
        return Promise.resolve();
    }

    return Promise.resolve();
};

export default authProvider;
