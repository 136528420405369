import polyglotI18nProvider from "ra-i18n-polyglot";
import spanishMessages from "@blackbox-vision/ra-language-spanish";

const messages = {
    es: {
        ...spanishMessages,
        "token is not found": "Usuario no autorizado",
        "Another object with the same value for property userPrincipalName already exists.":
            "Ya existe un usuario con estos datos",
        "In Strategy.prototype.jwtVerify: cannot verify token":
            "Sesión caducada"
    }
};

export const i18nProvider = polyglotI18nProvider(
    locale => messages[locale],
    "es",
    { allowMissing: true }
);
