// Custom layout for React Admin
import React from "react";
import { Layout } from "react-admin";
import RACustomMenu from "./RACustomMenu";

const RACustomLayout = props => (
    <Layout {...props} menu={RACustomMenu} />
);

export default RACustomLayout;
