import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  CheckboxGroupInput,
  RadioButtonGroupInput,
  FormDataConsumer,
  Toolbar,
  SaveButton
} from "react-admin";

import { PERMISSIONS, USER_TYPES } from "../../configs/constants";
import {
  validateEmail,
  validateNames,
  validatePermissions,
  validateSurnames,
  validateUser,
  validateUserType,
  validateUserId
} from "../../shared/validators";
import MultipleRutInput from "../../components/MultipleRutInput";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  simpleForm: {
    "& .MuiFormControl-root": {
      minHeigth: "50px",
      marginBottom: "30px"
    },
    "& .MuiToolbar-root": {
      backgroundColor: "transparent",
      paddingLeft: "16px",
      paddingBottom: "20px",
      "& .MuiButton-root": {
        minWidth: "300px",
        width: "30%",
        fontSize: "16px",
        fontWeight: 400
      }
    }
  }
});

const userTypeOptions = [
  { display: "Externo", value: USER_TYPES.EXTERNAL },
  { display: "Interno", value: USER_TYPES.INTERNAL }
];

const permissionsOptions = [
  { display: "Información de operaciones", value: PERMISSIONS.OPERATIONS },
  { display: "Información financiera", value: PERMISSIONS.FINANCES },
  { display: "Gerencia de productores", value: PERMISSIONS.MANAGEMENT },
  { display: "Otra información", value: PERMISSIONS.OTHERS }
];

const UserCreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="GUARDAR USUARIO" icon={<div></div>} />
  </Toolbar>
);

export const UserCreate = props => {
  const classes = useStyles();

  return (
    <Create
      {...props}
      title={"Crear usuario"}
      successMessage="Usuario creado exitosamente"
    >
      <SimpleForm
        redirect="list"
        submitOnEnter={false}
        validate={validateUser}
        className={classes.simpleForm}
        toolbar={<UserCreateToolbar />}
      >
        <TextInput
          source="username"
          label="Id de usuario"
          validate={validateUserId}
          parse={value => value.toLowerCase()}
        />
        <TextInput
          source="names"
          label="Nombres"
          validate={validateNames}
          variant="filled"
        />
        <TextInput
          source="surnames"
          label="Apellidos"
          validate={validateSurnames}
        />
        <TextInput source="email" label="Email" validate={validateEmail} />
        <RadioButtonGroupInput
          source="userType"
          label="Tipo de usuario"
          choices={userTypeOptions}
          optionText="display"
          optionValue="value"
          validate={validateUserType}
          defaultValue={USER_TYPES.EXTERNAL}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.userType === "EXTERNO" && (
              <MultipleRutInput
                label="Rut Productor"
                source="rutProducers"
                {...rest}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) => <CheckboxGroupInput
              source="permissions"
              label="Permisos"
              choices={formData.userType === "INTERNO" ? permissionsOptions : permissionsOptions.filter(x => x.value !== 'manager')}
              optionText="display"
              optionValue="value"
              validate={validatePermissions}
            />
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
