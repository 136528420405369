// This file contains helpers function to validate forms and inputs
// in the forms being used

export function currentDateValidation(value, allValues) {
  if (!value) {
    return "Este campo es requerido";
  }
  if (value < new Date()) {
    return "La fecha de expiración no puede ser una fecha pasada";
  }
  return undefined;
}
