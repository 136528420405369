import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = {
    root: {
      display: 'flex',
      flexDirection: 'column'
    },
    rut: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#EFEFEF",
        width: 123,
        height: 36,
        paddingLeft: 15,
        marginTop: 6,
        color: "#464646",
        fontSize: 14,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif"
    }
};

const MultiRutField = ({ source, classes, record = {} }) => (
    <div className={classes.root}>
        {record[source] instanceof Array &&
            record[source].map(rut => (
                <div key={rut} className={classes.rut}>
                    {rut}
                </div>
            ))}
    </div>
);

MultiRutField.defaultProps = {
    label: "Ruts de Productor",
    addLabel: true
};

MultiRutField.propTypes = {
    label: PropTypes.string,
    addLabel: PropTypes.bool,
    source: PropTypes.string.isRequired,
    record: PropTypes.object
};

export { MultiRutField };
export default withStyles(styles)(MultiRutField);
