import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Filter, TextInput } from "react-admin";

import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";

const useStyles = makeStyles({
  form: {
    marginTop: 0,
    minHeight: "auto",
    width: "30%",
    "& .filter-field": {
      width: "100%"
    },
    "& .MuiFilledInput-input": {
      border: "1px solid #EBEBEB",
      backgroundColor: "transparent",
      padding: "5px 5px 5px 30px"
    },
    "& .MuiFilledInput-root": {
      background: "none",
      borderRadius: "4px"
    },
    "& .MuiFilledInput-underline": {
      "&:before": {
        left: 0,
        right: 0,
        bottom: 0,
        content: "",
        position: "absolute",
        transition: "none",
        borderBottom: "none",
        pointerEvents: "none"
      },
      "&:after": {
        left: 0,
        right: 0,
        bottom: 0,
        content: "",
        position: "absolute",
        transition: "none",
        transform: "",
        borderBottom: "none",
        pointerEvents: "none"
      }
    },
    "& .MuiFormControl-root": {
      marginTop: 0,
      marginBottom: 0,
      width: "100%",
      "& > p": {
        display: "none"
      }
    }
  },
  userSearchContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%"
  },
  userSearchIcon: {
    position: "absolute",
    paddingLeft: "10px",
    zIndex: 100
  }
});

export const UserFilter = props => {
  const classes = useStyles();
  return (
    <Filter className={classes.form} {...props}>
      <div
        id="user-search-filter"
        className={classes.userSearchContainer}
        alwaysOn
      >
        <SearchIcon className={classes.userSearchIcon} />
        <TextInput label="" source="search" />
      </div>
    </Filter>
  );
};
