import React from "react";
import { List } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  list: {
    backgroundColor: "white",
    boxShadow: "0px 2px 6px #0000001D",
    padding: "25px",
    "& .MuiPaper-root": {
      boxShadow: "none"
    },
    "& .MuiToolbar-root": {
      alignItems: "center",
      marginBottom: "10px"
    }
  }
});

export const RACustomList = props => {
  const classes = useStyles();
  const { children, ...customProps } = props;
  return (
    <List {...customProps} className={classes.list}>
      {children}
    </List>
  );
};
